<template>
  <div>
    <a-row :gutter="24">
      <a-col :md="24">
        <a-card :bordered="false">
          <!-- 查询区域 -->
          <div class="table-page-search-top">
            <div class="table-page-search-wrapper table-page-search-header">
              <!-- 搜索区域 -->
              <a-form layout="inline" @keyup.enter.native="searchQuery">
                <a-row :gutter="24">
                  <a-col :span="6">
                    <a-form-item
                      label="邀请码"
                      :labelCol="{ span: 5 }"
                      :wrapperCol="{ span: 18, offset: 1 }"
                    >
                      <a-input
                        placeholder="邀请码"
                        v-model="queryParam.invitationCode"
                        allow-clear
                        @input="inputSearchQuery"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="6">
                    <a-form-item
                      label="上級Near地址"
                      :labelCol="{ span: 5 }"
                      :wrapperCol="{ span: 18, offset: 1 }"
                    >
                      <a-input
                        placeholder="上級Near地址"
                        v-model="queryParam.upperNearAddress"
                        allow-clear
                        @input="inputSearchQuery"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="8">
                    <a-form-item
                      label="邀请人数時間篩選"
                      :labelCol="{ span: 5 }"
                      :wrapperCol="{ span: 10, offset: 1 }"
                    >
                      <a-range-picker
                        :value-format="dateFormat"
                        v-model="completeTime"
                        :ranges="ranges"
                      >
                      </a-range-picker>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </div>
            <div class="table-page-search">
              <div class="button-onditions">
                <a-button icon="search" type="primary" @click="searchQuery"
                  >查詢</a-button
                >
                <!-- <a-button icon="sync" @click="searchReset">重置</a-button> -->
              </div>
            </div>
          </div>
          <!-- 操作按钮区域 -->
          <div class="table-operator" style="margin-top: 5px">
            <a-button
              type="primary"
              icon="download"
              @click="handleExportXls('交易清單')"
              :loading="exportLoading"
              id="export"
              >導出</a-button
            >
          </div>
          <!-- table区域-begin -->
          <div>
            <a-table
              ref="table"
              size="middle"
              bordered
              rowKey="id"
              :columns="columns"
              :dataSource="dataSource"
              :pagination="ipagination"
              :loading="loading"
              @change="handleTableChange"
              class="table_info"
              :scroll="{ x: scrollX }"
            >
              <!-- <span slot="action" slot-scope="text, record">
                  <a-tag
                    color="#87d068"
                    v-if="btnEnableList.indexOf(1) > -1"
                    @click="handleEdit(record)"
                    >编辑</a-tag
                  >
                  <a-popconfirm
                    v-if="btnEnableList.indexOf(1) > -1"
                    title="确定删除吗?"
                    @confirm="() => handleDelete(record.id)"
                  >
                    <a-tag color="#f50">删除</a-tag>
                  </a-popconfirm>
                </span> -->
            </a-table>
          </div>
          <!-- table区域-end -->
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { JeecgListMixin } from "@/mixins/JeecgListMixin";
import { AdapterTableMixin } from "@/mixins/AdapterTableMixin";
import { downFile, postAction } from "@/api/manage";
import moment from "moment";

export default {
  name: "invitationList",
  mixins: [JeecgListMixin, AdapterTableMixin],
  components: {},
  data() {
    return {
      // 查询条件
      queryParam: {},
      // 员工
      staffList: [],

      // 表头
      columns: [
        {
          title: "#",
          dataIndex: "",
          width: 40,
          align: "center",
          customRender(t, r, index) {
            return parseInt(index) + 1;
          },
        },
        {
          title: "郵箱賬號",
          align: "center",
          dataIndex: "inviteeName",
        },
        {
          title: "邀請碼",
          align: "center",
          dataIndex: "invitationCode",
        },
        {
          title: "Near地址",
          align: "center",
          dataIndex: "nearAddress",
        },
        {
          title: "evm地址",
          align: "center",
          dataIndex: "galxeBindAddress",
        },
        {
          title: "邀请人数",
          align: "center",
          dataIndex: "inviteesNum",
        },
        {
          title: "上級邀請碼",
          align: "center",
          dataIndex: "upperInvitorCode",
        },
        {
          title: "上級Near地址",
          align: "center",
          dataIndex: "upperNearAddress",
        },
        // {
        //   title: "操作",
        //   dataIndex: "action",
        //   align: "center",
        //   scopedSlots: { customRender: "action" },
        // },
      ],
      url: {
        list: "/invitation/page",
        exportXlsUrl: "/invitation/export",
      },
      dateFormat: "YYYY-MM-DD",
      completeTime: [],
      ranges: {
          本周: [
            moment().startOf("week"),
            moment().endOf("week").subtract(0, "days"),
          ],
          本月: [moment().startOf("month"), moment().endOf("month")],
          本季度: [
            moment().startOf("quarter"),
            moment().endOf("quarter").subtract(0, "days"),
          ],
          本年: [moment().startOf("year"), moment().endOf("year")],
        },
      exportLoading: false,
    };
  },
  computed: {},
  created() {},
  methods: {
    handleEdit(record) {
      this.$refs.modalForm.edit(record);
      this.$refs.modalForm.title = "编辑";
      if (this.btnEnableList.indexOf(1) === -1) {
        this.$refs.modalForm.isReadOnly = true;
      }
    },
    handleExportXls(fileName, url) {
      if (this.exportLoading) {
        this.$message.warning("其他文件正在导出，请稍后再试！");
        return false;
      }
      let exportXlsUrl;
      if (url) {
        exportXlsUrl = url;
      } else {
        exportXlsUrl = this.url.exportXlsUrl;
      }

      this.exportLoading = true;
      if (!fileName || typeof fileName != "string") {
        fileName = "导出文件";
      }
      let param = { ...this.queryParam };
      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        param["ids"] = this.selectedRowKeys.join(",");
      }
      downFile(exportXlsUrl, param).then((data) => {
        if (!data) {
          this.$message.warning("文件下载失败");
          this.exportLoading = false;
          return;
        }
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          window.navigator.msSaveBlob(
            new Blob([data], { type: "application/vnd.ms-excel" }),
            fileName + ".xls"
          );
          this.exportLoading = false;
        } else {
          let url = window.URL.createObjectURL(
            new Blob([data], { type: "application/vnd.ms-excel" })
          );
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName + ".xls");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
          this.exportLoading = false;
        }
      });
    },
  },
  watch: {
    completeTime: {
      handler(val, oldVal) {
        if (val.length > 0) {
          let start = new Date(val[0] + " 00:00:00").getTime();
          let end = new Date(val[1] + " 23:59:59").getTime();
          this.$set(this.queryParam, "startTime", start);
          this.$set(this.queryParam, "endTime", end);
        } else {
          delete this.queryParam.startTime;
          delete this.queryParam.endTime;
        }
        this.searchQuery();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
@import "~@assets/less/common.less";
@import "~@assets/less/button.less";
@import "~@assets/less/TaskProcess.less";
.table_info ::v-deep .ant-table-tbody > tr > td {
  padding: 5px 8px !important;
  overflow-wrap: break-word;
  font-weight: 500;
}
</style>
