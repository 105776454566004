/**
 * 新增修改完成调用 modalFormOk方法 编辑弹框组件ref定义为modalForm
 * 高级查询按钮调用 superQuery方法  高级查询组件ref定义为superQueryModal
 * data中url定义 list为查询列表  delete为删除单条记录  deleteBatch为批量删除
 */
import { filterObj } from "@/utils/util";
import {
  deleteAction,
  getAction,
  postAction,
  downFile,
  getFileAccessHttpUrl,
} from "@/api/manage";
import Vue from "vue";
import { ACCESS_TOKEN } from "@/store/mutation-types";

export const JeecgListMixin = {
  data() {
    return {
      //token header
      tokenHeader: { "X-Access-Token": Vue.ls.get(ACCESS_TOKEN) },
      /* 查询条件-请不要在queryParam中声明非字符串值的属性 */
      queryParam: {},
      /* 数据源 */
      e: [],
      dataSource: [],
      /* 分页参数 */
      ipagination: {
        current: 1,
        pageSize: 15,
        pageSizeOptions: ["15", "30", "50", "100"],
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
      },
      /* 排序参数 */
      isorter: {
        column: "id",
        order: "desc",
      },
      /* 筛选参数 */
      filters: {},
      /* table加载状态 */
      loading: false,
      /* table选中keys*/
      selectedRowKeys: [],
      /* table选中records*/
      selectionRows: [],
      /* 查询折叠 */
      toggleSearchStatus: false,
      /* 高级查询条件生效状态 */
      superQueryFlag: false,
      /* 高级查询条件 */
      superQueryParams: "",
      /** 高级查询拼接方式 */
      superQueryMatchType: "and",
      /** 是否加载时就执行 */
      disableMixinCreated: false,
      /* 按钮权限 */
      btnEnableList: "",
      timer: null,
    };
  },
  created() {
    if (!this.disableMixinCreated) {
      //console.log(' -- mixin created -- ')
      this.loadData();
      //初始化字典配置 在自己页面定义
      this.initDictConfig();
      //初始化按钮权限
      this.initActiveBtnStr();
    }
  },
  methods: {
    // 加载列表
    loadData(arg) {
      // console.log("arg",arg)
      if (!this.url.list) {
        this.$message.error("请设置url.list属性!");
        return;
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1;
      }
      var params = this.getQueryParams(); //查询条件
      this.loading = true;
      // if (params.search.includes("todayTracking")) {
      //   let newParams = {};
      //   // newParams = JSON.parse(params.search)
      //   newParams.todayTracking = true;
      //   newParams.isGaControl = JSON.parse(params.search).isGaControl;
      //   params.search = JSON.stringify(newParams);
      // }
      if (params)
        getAction(this.url.list, params).then((res) => {
          if (res.code === 200) {
            this.dataSource = [];
            // this.dataSource = res.data.rows || res.data;
            if (!!res.data.rows && res.data.rows.length > 0) {
              this.dataSource = res.data.rows;
            }
            if (!!res.data && res.data.length > 0) {
              this.dataSource = res.data;
            }
            this.ipagination.total = res.data.total;
          }
          if (res.code === 510) {
            this.$message.warning(res.data);
          }
          this.loading = false;
        });
      this.selectedRowKeys = [];
      this.selectionRows = [];
    },
    initDictConfig() {
      //console.log("--这是一个假的方法!")
    },
    handleSuperQuery(params, matchType) {
      //高级查询方法
      if (!params) {
        this.superQueryParams = "";
        this.superQueryFlag = false;
      } else {
        this.superQueryFlag = true;
        this.superQueryParams = JSON.stringify(params);
        this.superQueryMatchType = matchType;
      }
      this.loadData(1);
    },
    getQueryParams() {
      //获取查询条件
      let sqp = {};
      if (this.superQueryParams) {
        sqp["superQueryParams"] = encodeURI(this.superQueryParams);
        sqp["superQueryMatchType"] = this.superQueryMatchType;
      }
      let searchObj = {};
      searchObj.search = JSON.stringify(this.queryParam);
      // var param = Object.assign(sqp, searchObj, this.isorter, this.filters);
      var param = Object.assign(
        sqp,
        { ...this.queryParam },
        this.isorter,
        this.filters
      );
      param.pageNum = this.ipagination.current;
      // param.currentPage = this.ipagination.current;
      param.pageSize = this.ipagination.pageSize;
      return filterObj(param);
    },

    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    onClearSelected() {
      this.selectedRowKeys = [];
      this.selectionRows = [];
    },
    searchQuery() {
      this.loadData(1);
    },
    inputSearchQuery() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.loadData(1);
      }, 500);
    },
    superQuery() {
      this.$refs.superQueryModal.show();
    },
    searchReset() {
      this.queryParam = {};
      this.loadData(1);
    },
    searchResetGa() {
      this.queryParam = { isGaControl: true };
      this.loadData(1);
    },
    searchResetMj() {
      this.queryParam = { isGaControl: false };
      this.loadData(1);
    },
    batchSetStatus: function (status) {
      if (!this.url.batchSetStatusUrl) {
        this.$message.error("请设置url.batchSetStatusUrl属性!");
        return;
      }
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning("请选择一条记录！");
        return;
      } else {
        var ids = "";
        for (var a = 0; a < this.selectedRowKeys.length; a++) {
          ids += this.selectedRowKeys[a] + ",";
        }
        var that = this;
        this.$confirm({
          title: "确认操作",
          content: "是否操作选中数据?",
          onOk: function () {
            that.loading = true;
            postAction(that.url.batchSetStatusUrl, { status: status, ids: ids })
              .then((res) => {
                if (res.code === 200) {
                  that.loadData();
                  that.onClearSelected();
                } else {
                  that.$message.warning(res.data.message);
                }
              })
              .finally(() => {
                that.loading = false;
              });
          },
        });
      }
    },
    // 批量删除
    batchDel: function () {
      if (!this.url.deleteBatch) {
        this.$message.error("请设置url.deleteBatch属性!");
        return;
      }
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning("请选择一条记录！");
        return;
      } else {
        var ids = "";
        for (var a = 0; a < this.selectedRowKeys.length; a++) {
          ids += this.selectedRowKeys[a] + ",";
        }
        var that = this;
        this.$confirm({
          title: "确认删除",
          content: "是否删除选中数据?",
          onOk: function () {
            that.loading = true;
            deleteAction(that.url.deleteBatch, { ids: ids })
              .then((res) => {
                if (res.code === 200) {
                  that.loadData();
                  that.onClearSelected();
                } else {
                  that.$message.warning(res.data.message);
                }
              })
              .finally(() => {
                that.loading = false;
              });
          },
        });
      }
    },
    //单个删除
    handleDelete: function (id) {
      if (!!id.userCountLimit && id.userCountLimit != 0) {
        this.$message.error("管理员账号不能删除!!");
        return;
      }
      if (!this.url.delete) {
        this.$message.error("请设置url.delete属性!");
        return;
      }
      var that = this;
      deleteAction(that.url.delete, { id: id }).then((res) => {
        if (res.code === 200) {
          that.loadData();
        } else {
          that.$message.warning(res.data.message);
        }
      });
    },
    // 完成任务
    handleCompletion: function (id) {
      if (!this.url.completion) {
        this.$message.error("请设置url.completion!");
        return;
      }
    },
    //发布协查记录--生成协查函
    handleCreateLetter: function (id) {},
    // 关闭任务
    handleClose: function (params) {
      if (!this.url.close) {
        this.$message.error("请设置url.close!");
        return;
      }
    },

    // 删除任务
    deleteTask: function (id) {
      if (!this.url.deleteTask) {
        this.$message.error("请设置url.deleteTask!");
        return;
      }
      var that = this;
      deleteAction(that.url.deleteTask, { id }).then((res) => {
        if (res.code === 200) {
          that.loadData();
        } else {
          that.$message.warning(res.data.message);
        }
      });
    },

    handleEdit: function (record) {
      this.$refs.modalForm.edit(record);
      this.$refs.modalForm.title = "编辑";
      this.$refs.modalForm.disableSubmit = false;
    },
    handleAdd: function (record) {
      this.$refs.modalForm.add(record);
      this.$refs.modalForm.title = "新增";
      this.$refs.modalForm.disableSubmit = false;
    },
    handleCommonAdd: function (record) {
      record.personType = 0;
      this.$refs.modalForm.add(record);
      this.$refs.modalForm.title = "新增";
      this.$refs.modalForm.disableSubmit = false;
    },
    handleAdminAdd: function (record) {
      record.personType = 1;
      this.$refs.modalForm.add(record);
      this.$refs.modalForm.title = "新增";
      this.$refs.modalForm.disableSubmit = false;
    },
    handleTableChange(pagination, filters, sorter) {
      //分页、排序、筛选变化时触发
      //TODO 筛选
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field;
        this.isorter.order = "ascend" == sorter.order ? "asc" : "desc";
      }
      this.ipagination = pagination;
      this.loadData();
    },
    handleToggleSearch() {
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    // 给popup查询使用(查询区域不支持回填多个字段，限制只返回一个字段)
    getPopupField(fields) {
      return fields.split(",")[0];
    },
    modalFormOk() {
      // 新增/修改 成功时，重载列表
      this.loadData();
    },
    handleDetail: function (record, type) {
      this.$refs.modalDetail.show(record, type);
      this.$refs.modalDetail.title = type + "-详情";
    },
    /* 导出 */
    handleExportXls2() {
      let paramsStr = encodeURI(JSON.stringify(this.getQueryParams()));
      let url = `${window._CONFIG["domianURL"]}/${this.url.exportXlsUrl}?paramsStr=${paramsStr}`;
      window.location.href = url;
    },
    handleExportXls(fileName) {
      if (!fileName || typeof fileName != "string") {
        fileName = "导出文件";
      }
      let param = {
        search: this.queryParam,
      };
      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        param["selections"] = this.selectedRowKeys.join(",");
      }
      downFile(this.url.exportXlsUrl, param).then((data) => {
        if (!data) {
          this.$message.warning("文件下载失败");
          return;
        }
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          window.navigator.msSaveBlob(
            new Blob([data], { type: "application/vnd.ms-excel" }),
            fileName + ".xls"
          );
        } else {
          let url = window.URL.createObjectURL(
            new Blob([data], { type: "application/vnd.ms-excel" })
          );
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName + ".xls");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
      });
    },
    /* 导入 */
    handleImportExcel(info) {
      this.loading = true;
      // if (info.file.status !== 'uploading') {
      //   console.log("shinima", info.file, info.fileList);
      // }

      if (info.file.status === "done") {
        if (info.file.response) {
          if (info.file.response.code === 200) {
            this.$message.success("文件导入成功");
            this.loadData();
          } else {
            if (info.file.response.message) {
              this.$message.warning(info.file.response.message);
            } else {
              this.$message.warning("文件导入失败");
            }
          }
        } else {
          this.$message.error(`${info.file.name} ${info.file.response.data}.`);
        }
        this.loading = false;
      } else if (info.file.status === "error") {
        this.$message.error(`文件上传失败: ${info.file.msg} `);
        this.loading = false;
      }
    },
    /* 图片预览 */
    getImgView(text) {
      if (text && text.indexOf(",") > 0) {
        text = text.substring(0, text.indexOf(","));
      }
      return getFileAccessHttpUrl(text);
    },
    /* 文件下载 */
    uploadFile(text) {
      if (!text) {
        this.$message.warning("未知的文件");
        return;
      }
      if (text.indexOf(",") > 0) {
        text = text.substring(0, text.indexOf(","));
      }
      let url = getFileAccessHttpUrl(text);
      window.open(url);
    },
    /* 按钮权限 */
    initActiveBtnStr() {
      let funId = Vue.ls.get("funId"); //功能id
      let btnStrList = Vue.ls.get("winBtnStrList"); //按钮功能列表 JSON字符串
      this.btnEnableList = ""; //按钮列表
      if (funId && btnStrList) {
        for (let i = 0; i < btnStrList.length; i++) {
          if (btnStrList[i].funId == funId) {
            if (btnStrList[i].btnStr) {
              this.btnEnableList = btnStrList[i].btnStr;
            }
          }
        }
      }
    },
  },
};
