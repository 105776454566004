export const AdapterTableMixin = {
    data() {
        return {
            scrollY: document.body.clientHeight - 300, // 表格竖向滚动条
            hasTabScrollY: document.body.clientHeight - 460, // 表格竖向滚动条
            hasTabButtonScrollY: document.body.clientHeight - 500, // 表格竖向滚动条
            hasButton1ScrollY: document.body.clientHeight - 350, // 表格竖向滚动条
            hasButton2ScrollY: document.body.clientHeight - 400, // 表格竖向滚动条
            scrollX: document.body.clientWidth - 300, // 表格横向向滚动条
            screenHeight: document.body.clientHeight, // 屏幕的高度
            screenWeight: document.body.clientWidth, // 屏幕的宽度
        }
    },
    watch: {
        screenHeight (val) {
            // 初始化表格高度
            this.scrollY = val - 300
            this.hasTabScrollY = val - 460
            this.hasTabButtonScrollY = val - 500
            this.hasButton1ScrollY = val - 350
            this.hasButton2ScrollY = val - 400
        },
        screenWeight(val) {
            this.scrollX = val - 300;
        }
    },
    mounted () {
        // 监听屏幕高度
        window.onresize = () => {
          return (() => {
            window.screenHeight = document.body.clientHeight
            this.screenHeight = window.screenHeight
            window.screenWeight = document.body.clientWidth
            this.screenWeight = window.screenWeight
          })()
        }
    },
}